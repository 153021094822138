import React from 'react';
import { Link } from 'react-router-dom';

import Countdown from '../components/countdown/Countdown';
import Message from '../components/message/Message';
import Page from '../components/page/Page';
import PageHeader from '../components/pageHeader/PageHeader';
import galleryImage1 from '../images/DSC_7056.jpeg';
import galleryImage3 from '../images/DSC_7962.jpeg';
import galleryImage2 from '../images/DSC_8169.jpeg';
import troyAndErin from '../images/troy_erin_illustration.png';
import data from '../site-data.json';
import styles from './about.module.scss';

const About = (): JSX.Element => (
  <Page title={`${data.siteName} - About Us`}>
    <PageHeader
      title="About Us"
      image={galleryImage1}
      containerClassName={styles.pageHeaderContainer}
    />
    <Countdown
      image={galleryImage2}
      title="Our Centre"
      content={
        <>
          <p>
            Our centre is always striving to go above and beyond to ensure our
            village of children, families and staff are safe, secure and cared
            for.
          </p>
          <p>
            From the moment you walk through the front door you&apos;ll feel a
            warm sense of home-away-from-home with brand new modern furnishings,
            equipment and resources that are well maintained.
          </p>
          <p>
            We are a child-safe early education service that is committed to
            implementing the{' '}
            <a
              href="https://ocg.nsw.gov.au/child-safe-scheme"
              target="_blank"
              rel="noopener noreferrer"
            >
              Child Safe Standards
            </a>
            . This includes ensuring our visitors have a current Working With
            Children Check and our educators are continually trained in child
            protection to keep them safe.
          </p>
          <p>
            We believe learning can happen anywhere and anytime. Our
            environments are specifically designed to support opportunities to
            learn through play - all day, every day.
          </p>
        </>
      }
      backgroundColour="accent4"
    />
    <Countdown
      imageLeft
      image={galleryImage3}
      title="Team"
      content={
        <>
          <p>
            Our teams are vital to the high level of care we provide your
            family. Which is why we have developed an induction process that
            includes onsite and online training prior to our educators working
            with our families.
          </p>
          <p>
            We provide specialised{' '}
            <a
              href="https://www.circleofsecurityinternational.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Circle Of Security
            </a>{' '}
            International Training from our in-house certified facilitator. This
            specialised training ensures our educators;
          </p>
          <ul>
            <li>
              Provide the freedom and support to develop the confidence needed
              for your child to discover their world
            </li>
            <li>
              Help your child feel assured they can come and seek help and have
              their cup filled when they need and,
            </li>
            <li>
              Our educators are committed to your child&apos;s connection to
              their world by caring in a kind and secure way
            </li>
          </ul>
          <p>
            This support and training is also offered to families for free
            throughout the year.
          </p>
          <p>
            All of our staff are fully trained in first aid and have monthly
            professional development to support continual growth and innovation.
          </p>
        </>
      }
      backgroundColour="accent4"
    />
    <Message
      title="‘Who dares to teach must never cease to learn’ — John Cotton Dana"
      backgroundColour="accent4"
    />
  </Page>
);

export default About;
