/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import 'photoswipe/dist/photoswipe.css';

import React from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';

import Page from '../../components/page/Page';
import PageHeader from '../../components/pageHeader/PageHeader';
import headerImage from '../../images/DSC_7123.jpeg';
import galleryImage3 from '../../images/DSC_8063.jpeg';
import galleryImage3Thumbnail from '../../images/DSC_8063_thumbnail.jpeg';
import galleryImage1 from '../../images/DSC_8123.jpeg';
import galleryImage1Thumbnail from '../../images/DSC_8123_thumbnail.jpeg';
import galleryImage2 from '../../images/DSC_8196.jpeg';
import galleryImage2Thumbnail from '../../images/DSC_8196_thumbnail.jpeg';
import styles from './program.module.scss';

const ProgramPreschool = (): JSX.Element => (
  <Page title="Preschool Room" pageClassName={styles.page}>
    <PageHeader title="Preschool Room" image={headerImage} />
    <div className={styles.content}>
      <h1>Being school and life ready</h1>
      <p>
        We take a holistic approach to our School Readiness Program to ensure
        children have the opportunity to learn, play and participate in
        intentional teaching sessions. Our goal is for children to enter school
        with the skills and confidence to be the best they can be.
      </p>
      <p>Our preschool program includes:</p>
      <ul>
        <li>
          university qualified early childhood teacher developed learning
          programs
        </li>
        <li>
          specific menu designed with nutritious and delicious meals prepared
          fresh
        </li>
        <li>daily communication with families via Kinderm8 app</li>
        <li>
          resources to extend children’s interests and learning with technology{' '}
        </li>
        <li>
          literacy and numeracy embedded into play-based learning experiences
        </li>
        <li>
          the Reading Eggs program is provided free to our Preschool children to
          use at the centre and at home
        </li>
        <li>
          our School Readiness Program has been developed using NSW Department
          of Education, Early Stage 1 Curriculum
        </li>
      </ul>
      <div className={styles.ctaLinkContainer}>
        <a
          href="https://ccs-createimaginelearnkiama.kinderm8.com.au/enrolment-form"
          target="_blank"
          className={styles.ctaLink}
          rel="noopener noreferrer"
        >
          <button className={styles.solidButton} type="button">
            Enrol Now
          </button>
        </a>
      </div>

      <Gallery>
        <ul className={styles.imageGallery}>
          <Item
            original={galleryImage1}
            thumbnail={galleryImage1Thumbnail}
            width="1200"
            height="800"
          >
            {({ ref, open }) => (
              <li>
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onClick={open}
                  src={galleryImage1Thumbnail}
                  alt="1"
                  className={styles.galleryImage}
                />
              </li>
            )}
          </Item>
          <Item
            original={galleryImage2}
            thumbnail={galleryImage2Thumbnail}
            width="1200"
            height="800"
          >
            {({ ref, open }) => (
              <li>
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onClick={open}
                  src={galleryImage2Thumbnail}
                  alt="1"
                  className={styles.galleryImage}
                />
              </li>
            )}
          </Item>
          <Item
            original={galleryImage3}
            thumbnail={galleryImage3Thumbnail}
            width="1200"
            height="800"
          >
            {({ ref, open }) => (
              <li>
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onClick={open}
                  src={galleryImage3Thumbnail}
                  alt="1"
                  className={styles.galleryImage}
                />
              </li>
            )}
          </Item>
        </ul>
      </Gallery>
    </div>
  </Page>
);

export default ProgramPreschool;
