import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import YouTube, { YouTubeProps } from 'react-youtube';

import colors from '../../styles/colors.scss';
import styles from './hero.module.scss';

interface Props {
  backgroundImageUrl?: string;
  youtubeVideoId?: string;
  title: string;
  subtitle?: string;
  cta?: {
    href: string;
    isExternalLink?: boolean;
    buttonStyle: string;
    buttonBackgroundColour: string;
    buttonTextColour: string;
    buttonText: string;
  };
}

const Hero = (props: Props): JSX.Element => {
  const { backgroundImageUrl, youtubeVideoId, title, subtitle, cta } = props;
  const videoRef = useRef<YouTube>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrored, setHasErrored] = useState(false);

  useEffect(() => {
    const handleFocus = () => {
      if (videoRef?.current) {
        videoRef.current.internalPlayer?.playVideo();
      }
    };

    const handleBlur = () => {
      if (videoRef?.current) {
        videoRef.current.internalPlayer?.pauseVideo();
      }
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  const renderInnerContent = () => (
    <div className={styles.titles}>
      <h1>{title}</h1>
      {subtitle && (
        <h2 style={isLoading ? { color: colors.white } : {}}>{subtitle}</h2>
      )}
      {cta && (
        <div className={styles.ctaLinkContainer}>
          {cta.isExternalLink ? (
            <a
              href="https://ccs-createimaginelearnkiama.kinderm8.com.au/parent-tour-form"
              target="_blank"
              className={styles.ctaLink}
              rel="noopener noreferrer"
            >
              <button
                className={
                  cta.buttonStyle === 'solid'
                    ? styles.solidButton
                    : styles.textButton
                }
                style={{
                  backgroundColor: cta.buttonBackgroundColour,
                  borderColor: cta.buttonBackgroundColour,
                  color: cta.buttonTextColour,
                }}
                type="button"
              >
                {cta.buttonText}
              </button>
            </a>
          ) : (
            <Link to={cta.href} className={styles.ctaLink}>
              <button
                className={
                  cta.buttonStyle === 'solid'
                    ? styles.solidButton
                    : styles.textButton
                }
                style={{
                  backgroundColor: cta.buttonBackgroundColour,
                  borderColor: cta.buttonBackgroundColour,
                  color: cta.buttonTextColour,
                }}
                type="button"
              >
                {cta.buttonText}
              </button>
            </Link>
          )}
        </div>
      )}
    </div>
  );

  if (youtubeVideoId) {
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
      event.target.playVideo();
    };

    const onPlay: YouTubeProps['onPlay'] = (event) => {
      setIsLoading(false);
    };

    const onError: YouTubeProps['onError'] = (event) => {
      // eslint-disable-next-line no-console
      console.warn('Error has occurred loading background video');
      setHasErrored(true);
    };

    const onStateChange: YouTubeProps['onStateChange'] = (event) => {
      if (event?.data === 2) {
        // Do nothing
        // setHasErrored(true);
      }
    };

    const opts: YouTubeProps['opts'] = {
      height: 'auto',
      width: 'auto',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autohide: 1,
        autoplay: 1,
        mute: 1,
        fs: 0,
        showinfo: 0,
        modestBranding: 1,
        controls: 0,
        rel: 0,
        disablekb: 1,
        enablejsapi: 1,
        iv_load_policy: 3,
        playsinline: 1,
        loop: 1,
        start: 0,
        playlist: youtubeVideoId,
        origin:
          'http%3A%2F%2Fcreateimaginelearnkiama.com.au.s3-website-ap-southeast-2.amazonaws.com',
      },
      host: 'https://www.youtube-nocookie.com',
    } as any;

    return (
      <div className={styles.hero}>
        <div className={styles.videoBackground}>
          <YouTube
            id="player"
            ref={videoRef}
            videoId={youtubeVideoId}
            opts={opts}
            onReady={onPlayerReady}
            onStateChange={onStateChange}
            onPlay={onPlay}
            onError={onError}
          />
          <div
            className={styles.loadingBackground}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/background_video_screenshot.jpeg)`,
              opacity: hasErrored || isLoading ? 1 : 0,
            }}
          />
          <div className={styles.overlay}>{renderInnerContent()}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles.hero}
      style={{ backgroundImage: `url(${backgroundImageUrl}` }}
    >
      {renderInnerContent()}
    </div>
  );
};

export default Hero;
